import React from 'react'
import CleaningsList from './Cleanings/CleaningsList'
import { Redirect } from 'react-router-dom'

import AuthService from '../services/auth.service'

class Calendar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirect: false
    }
  }

  componentDidMount() {
    if(!AuthService.isAuthorized()) {
      this.setState({redirect: true})
    }
  }

  componentWillUnmount() {
    this.setState({redirect: false})
  }

  render() {
    if(this.state.redirect) {
      return <Redirect to="/logowanie" />
    }

    return (
      <div className="Calendar">
        <CleaningsList />
      </div>
    )
  }
}

export default Calendar