import React from 'react'
import Avatar from './Avatar'
import Logout from './Logout'

class LoggedUser extends React.Component {
  render() {
    return (
      <div className="LoggedUser">
        <Avatar />
        <Logout />
      </div>
    )
  }
}

export default LoggedUser