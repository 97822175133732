import React from 'react'
import NotificationItem from './NotificationItem'
import UserService from '../../services/user.service'

class NotificationsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      notifications: []
    }
  }
  componentDidMount() {
    UserService.getNotifications()
      .then(notifications => {
        this.setState({notifications: notifications})
      }, error => {
        console.log(error)
      })

    UserService.markNotificationsAsRead()
  }

  render() {
    let notifications = []
    if (this.state.notifications.length > 0) {
      notifications = this.state.notifications.map(notification => <NotificationItem key={notification.id} notification={notification} />)
    }

    return (
      <div>
        {notifications.length > 0 ? 
          notifications : 
          <div style={{textAlign: 'center'}}>
            Nie posiadasz żadnych powiadomień
          </div> }
      </div>
    )
  }
}

export default NotificationsList