import React from 'react'
import ReactTooltip from 'react-tooltip'

class Avatar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {}
    }
  }

  componentDidMount() {
    let user = JSON.parse(localStorage.getItem('user'))
    if(user) {
      let initials = user.name.match(/\b(\w)/g)
      initials = initials.join('')
      user.initials = initials
      this.setState({user})
    }
  }

  render() {
    return this.state.user ?
    (
      <div>
        <div data-html="true" data-tip={`Zalogowano jako <b>${this.state.user.name}</b>`} className="Avatar">
          <span className="Initials">{this.state.user.initials}</span>
        </div>
        <ReactTooltip />
      </div>
    ) : <div></div>
  }
}

export default Avatar