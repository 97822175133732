class Helper {
    isIOS() {
        const appleStuff = ['iPhone', 'iPad', 'iPod']

        if (!appleStuff.includes(window.navigator.platform)) {
            return false
        }
        
        return true
    }

    isNotIOS() {
        const appleStuff = ['iPhone', 'iPad', 'iPod']

        if (appleStuff.includes(window.navigator.platform)) {
            return false
        }
        
        return true
    }
}

export default new Helper()