import React from 'react'
import logo from '../../logo.png'
import ReactLoading from 'react-loading'

import AuthService from '../../services/auth.service'
import { Redirect } from 'react-router-dom'

class LoginPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      errors: [],
      loading: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(event) {
    const target = event.target
    const name = target.name
    const value = target.value

    this.setState({
      [name]: value
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      loading: true,
      errors: []
    })

    if(!this.validate()) {
      AuthService.login(this.state.username, this.state.password)
        .then((success) => {
        window.location.reload();
        }, error => {
          const resMessage = (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          this.setState(prevState => ({
            loading: false,
            message: resMessage,
            errors: [...prevState.errors, 'LOGIN_ERROR']
          }))
        })
    } else {
      this.setState({
        loading: false
      })
    }
  }

  validate() {
    let isError = false

    if(this.state.username && this.state.password) {
      this.setState({
        errors: []
      })

      isError = false
    }

    if(!this.state.username) {
      this.setState(prevState => ({
        errors: [...prevState.errors, 'USERNAME_REQUIRED']
      }))

      isError = true
    }

    if(!this.state.password) {
      this.setState(prevState => ({
        errors: [...prevState.errors, 'PASSWORD_REQUIRED']
      }))

      isError = true
    }

    return isError
  }

  render() {
    if(AuthService.isAuthorized()) {
      return <Redirect to="/" />
    }

    const currentYear = new Date().getFullYear()
    return (
      <div className="LoginPage">
        <div className="LoginWrapper Container">
          <div className="LoginForm">
            <div className="Logo">
              <img src={logo} alt="CWService Logo" />
            </div>
            <div className="LoginBox">
              <form onSubmit={this.handleSubmit}>
                <input onChange={this.handleChange} className={this.state.errors.includes('USERNAME_REQUIRED') ? 'InputError' : ''} value={this.state.username} name="username" type="text" placeholder="Nazwa użytkownika" />
                <input onChange={this.handleChange} className={this.state.errors.includes('PASSWORD_REQUIRED') ? 'InputError' : ''} value={this.state.password} name="password" type="password" placeholder="Hasło" />
                {this.state.errors.includes('LOGIN_ERROR')
                    ? <div className="LoginError">
                      Nieprawidłowa nazwa użytkownika lub hasło
                    </div>
                    : ''}
                {this.state.errors.includes('USERNAME_REQUIRED') || this.state.errors.includes('PASSWORD_REQUIRED')
                    ? <div className="LoginError">
                      Wypełnij wszystkie pola
                    </div>
                    : ''}
                <button type="submit">{
                  this.state.loading
                      ? <div className="ButtonLoading">
                        <ReactLoading type="cylon" color="#f2f2f2" />
                      </div>
                      : 'Zaloguj się'
                }</button>
              </form>
            </div>
          <div className="Copyrights">
            Stworzone przez <a href="http://softwise.pl">Softwise</a> &middot; {currentYear}
          </div>
            </div>
        </div>
      </div>
    )
  }
}

export default LoginPage