import * as PusherPushNotifications from "@pusher/push-notifications-web"
import Helper from './services/helper'

let beamsClient

if (Helper.isNotIOS()) {
    beamsClient = new PusherPushNotifications.Client({
        instanceId: 'e332bdad-78f3-4e90-bc1f-d5b249ec7bf9'
    });
}

export default beamsClient