import React from 'react'
import logo from './../logo.png'
import Container from './Container'
import LoggedUser from './LoggedUser'
import { Link } from 'react-router-dom'

class Navbar extends React.Component {
  render() {
    return (
      <Container>
        <div className="Navbar">
          <div className="Logo">
            <Link to="/">
              <img alt="CWService Sygnet" src={logo} />
            </Link>
          </div>
          <LoggedUser />
        </div>
      </Container>
    )
  }
}

export default Navbar