import axios from './interceptors'
import beamsClient from '../beams'
import * as PusherPushNotifications from "@pusher/push-notifications-web"
import Helper from './helper'

const API_URL = process.env.REACT_APP_API_URL

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + '/auth/login', {
        username,
        password
      })
      .then(res => {
        if(res.data.accessToken) {
          localStorage.setItem('auth', JSON.stringify(res.data))
          
          const accessToken = res.data.accessToken
          return this.getCurrentUser()
            .then(user => {
              if (Helper.isNotIOS()) {
                const userId = user.id
                const tokenProvider = new PusherPushNotifications.TokenProvider({
                  url: process.env.REACT_APP_API_URL + '/pusher/beams-auth', 
                  headers: { Authorization: 'Bearer ' + accessToken },
                })
                
                beamsClient
                  .start()
                  .then(() => beamsClient.setUserId(userId.toString(), tokenProvider))
                  .then(() => {
                  window.location.reload()
                  })
                  .catch(console.error)
              } else {
                window.location.reload()
              }
              return res.data
            })
        }
        else
          return res.data
        
      })
  }

  logout() {
    return axios
      .post(`${API_URL}/auth/logout`)
      .then(res => {
        if (Helper.isNotIOS()) {
          beamsClient
            .stop()
            .catch(console.error);
        }
        localStorage.removeItem('auth')
        localStorage.removeItem('user')
        window.location.reload(true)
      }, error => {
        if (Helper.isNotIOS()) {
          beamsClient
            .stop()
            .catch(console.error);
        }
        localStorage.removeItem('auth')
        localStorage.removeItem('user')
        window.location.reload(true)
      })
  }

  getCurrentUser() {
    return axios
      .post(`${API_URL}/auth/me`)
      .then(res => {
        if(res.data.id) {
          localStorage.setItem('user', JSON.stringify(res.data))
        }

        return res.data
      })
  }

  isAuthorized() {
    if(localStorage.getItem('auth') && localStorage.getItem('user')) {
      return true
    }

    return false
  }
}

export default new AuthService()