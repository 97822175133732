import React from 'react'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import Container from './components/Container'
import Panel from './components/Panel'
import Content from './components/Content'
import LoginPage from './components/Auth/LoginPage'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/logowanie">
            <LoginPage />
          </Route>
          <Route path="/">
            <Navbar/>
            <Container>
                <Panel>
                  <Sidebar />
                  <Content />
                </Panel>
            </Container>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
