import React from 'react'

class NotificationItem extends React.Component {
  render() {
    const createdAt = new Date(this.props.notification.created_at).toLocaleString('pl-PL')
    return (
      <div className={`NotificationItem ${this.props.notification.read_at ? '' : 'New'}`}>
        <span className="Message">{this.props.notification.data.message}</span>
        <span className="DateTime">{createdAt}</span>
      </div>
    )
  }
}

export default NotificationItem