import React from 'react'
import NavItem from './NavItem'
import { Bell, Calendar } from 'css.gg'
import UserService from '../services/user.service'

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasUnreadNotifications: false
    }
  }

  componentDidMount() {
    if (localStorage.getItem('user')) {
      UserService.hasUnreadNotifications()
        .then(res => {
          this.setState({hasUnreadNotifications: res})
        }, error => console.log(error))
    }
  }

  render() {
    return (
      <nav>
        <NavItem activeOnlyWhenExact={true} to="/">
          <Calendar />
          <span className="NavItem__text">Kalendarz</span>
        </NavItem>
        <NavItem to="/powiadomienia">
         <div className="IconWrapper">
           <Bell />
           {this.state.hasUnreadNotifications ?
              <i className="AlertDot"></i>
            : ''}
         </div>
         <span className="NavItem__text">Powiadomienia</span>
        </NavItem>
      </nav>
    )
  }
}

export default Sidebar