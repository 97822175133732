import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const auth = JSON.parse(localStorage.getItem('auth'))
    if (auth) {
        const token = auth.accessToken
        config.headers['Authorization'] = `Bearer ${token}`
    }

    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept'] = 'application/json'

    return config
}, function (error) {
    return Promise.reject(error)
})

axios.interceptors.response.use(response => {
    return response
}, error => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true

        if (error.response.status === 401 && originalRequest.url === `${API_URL}/auth/refresh`) {
            localStorage.removeItem('user')
            localStorage.removeItem('auth')
            window.location.reload(true)
            return Promise.reject(error)
        }

        return axios.post(`${API_URL}/auth/refresh`)
            .then(res => {
                localStorage.setItem('auth', JSON.stringify(res.data))

                axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.accessToken

                return axios(originalRequest)
            }, error => {
                localStorage.removeItem('user')
                localStorage.removeItem('auth')
                window.location.reload(true)
            })
    }

    return Promise.reject(error)
})

export default axios