import axios from './interceptors'

const API_URL = process.env.REACT_APP_API_URL

class UserService {
  getCleanings() {
    const user = JSON.parse(localStorage.getItem('user'))

    if(user) {
      return axios
        .get(API_URL + '/cleanings-for-employee/' + user.id)
        .then(res => {
          if(res) {
            return res.data
          }
        }, error => {
          return error
        })
    }
  }

  getNotifications() {
    const user = JSON.parse(localStorage.getItem('user'))

    if(user) {
      return axios
        .get(API_URL + '/latest-notifications/' + user.id)
        .then(res => {
          if(res) {
            return res.data
          }
        }, error => {
          return error
        })
    }
  }

  markNotificationsAsRead() {
    const user = JSON.parse(localStorage.getItem('user'))

    if(user) {
      return axios
        .post(API_URL + '/mark-notifications-as-read/' + user.id)
        .then(res => {
          if(res) {
            return res.data
          }
        }, error => {
          return error
        })
    }
  }

  hasUnreadNotifications() {
    const user = JSON.parse(localStorage.getItem('user'))

    if(user) {
      return axios
        .get(API_URL + '/has-unread-notifications/' + user.id)
        .then(res => {
          if(res) {
            return res.data
          }
        }, error => {
          return error
        })
    }
  }

  me() {
    return JSON.parse(localStorage.getItem('user'))
  }

  confirmCleanings() {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user) {
      return axios
        .post(API_URL + '/confirm-cleanings/' + user.id)
        .then(res => {
          if(res) {
            return res.data
          }
        }, error => {
          return error
        })
    }
  }

  hasConfirmedCleanings() {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user) {
      return axios
        .get(API_URL + '/has-confirmed-cleanings/' + user.id)
        .then(res => {
          if(res) {
            return res.data
          }
        }, error => {
          return error
        })
    }
  }
}

export default new UserService()