import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

function NavItem({ children, label, to, activeOnlyWhenExact}) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact
  })

  return (
    <Link to={to}>
      <div className={`NavItem ${match ? "NavItem--Active" : ""}`}>
        {children}
      </div>
    </Link>
  )
}

export default NavItem