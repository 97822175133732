import React from 'react'
import CleaningDetails from './CleaningDetails'

class CleaningItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {showDetails: false}
  }

  render() {
    return (
      <div className={`CleaningItem ${this.state.showDetails ? 'CleaningItem-Active' : ''}`}>
        <div className="Heading">
          <a style={{textDecoration: this.props.cleaning.canceled ? 'line-through' : ''}} href={`https://maps.google.com/maps?daddr=${this.props.cleaning.job_address ?? this.props.cleaning.cleaning_address}`} target="_blank" rel="noopener noreferrer">
            {this.props.cleaning.job_address ?? this.props.cleaning.cleaning_address}, {this.props.cleaning.job.owner.name}
          </a>
          <div className="Expander" onClick={() => this.setState({showDetails: !this.state.showDetails})}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                 className="feather feather-chevron-down">
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </div>
        </div>
        {this.state.showDetails ? <CleaningDetails details={this.props.cleaning} /> : "" }
      </div>
    )
  }
}

export default CleaningItem