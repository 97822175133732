import React from 'react'
import { LogOut as LogoutIcon } from 'css.gg'
import ReactTooltip from 'react-tooltip'

import AuthService from '../services/auth.service'

class Logout extends React.Component {
  render() {
    return (
      <div>
        <div onClick={this.logout} data-tip="Wyloguj" className="Logout">
          <LogoutIcon />
        </div>
        <ReactTooltip />
      </div>
    )
  }

  logout() {
    AuthService.logout()
  }
}

export default Logout