import React from 'react'
import CleaningItem from './CleaningItem'
import ReactLoading from 'react-loading'
import { Redirect } from 'react-router-dom'

import UserService from '../../services/user.service'

class CleaningsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fetching: false,
      todayCleanings: [],
      tomorrowCleanings: [],
      confirmedCleanings: false
    }
  }

  componentDidMount() {
    this.setState({fetching: true})

    const user = localStorage.getItem('user')
    if(user) {
      UserService.getCleanings()
          .then(res => {
            this.setState({
              fetching: false, 
              // todayCleanings: Object.values(res['TODAY']).sort((a, b) => (a.position > b.position) ? 1 : -1), 
              // tomorrowCleanings: Object.values(res['TOMORROW']).sort((a, b) => (a.position > b.position) ? 1 : -1)
              todayCleanings: Object.values(res['TODAY']).sort((a, b) => {
                if (a.position === 0 && b.position === 0) {
                  return parseInt(a.cleaning_from) > parseInt(b.cleaning_from) ? 1 : -1
                }

                return a.position > b.position ? 1 : -1
              }), 
              tomorrowCleanings: Object.values(res['TOMORROW']).sort((a, b) => {
                if (a.position === 0 && b.position === 0) {
                  return parseInt(a.cleaning_from) > parseInt(b.cleaning_from) ? 1 : -1
                }

                return a.position > b.position ? 1 : -1
              })
            })
          }, error => {
            console.log(error)
          })

      UserService.hasConfirmedCleanings()
          .then(res => {
            if (res.confirmed) {
              this.setState({confirmedCleanings: true})
            }
          }, error => console.log(error))
    }
  }

  componentWillUnmount() {
    this.setState({
      fetching: false,
      todayCleanings: [],
      tomorrowCleanings: []
    })
  }

  handleCleaningsConfirm() {
    UserService.confirmCleanings()
      .then(res => {
        this.setState({confirmedCleanings: true})
      }, error => {
        console.log(error)
      })
  }

  render() {
    const user = localStorage.getItem('user')
    if(!user) {
      return <Redirect to="/logowanie" />
    }

    let todayCleanings = [] 
    let tomorrowCleanings = []

    if (this.state.todayCleanings.length > 0) {
      todayCleanings = this.state.todayCleanings.map(cleaning => <CleaningItem key={cleaning.cleaning_id} cleaning={cleaning} />)
    }

    if (this.state.tomorrowCleanings.length > 0) {
      tomorrowCleanings = this.state.tomorrowCleanings.map(cleaning => <CleaningItem key={cleaning.cleaning_id} cleaning={cleaning} />)
    }

    return (
      <div className="CleaningsList">
        {todayCleanings.length > 0 ? 
          <div>
            <div className="PageDesc Today">DZISIEJSZE SPRZĄTANIA</div>
            {this.state.fetching ? <div className="LoadingWrapper"><ReactLoading type="spin" color="#f2f2f2" /></div> : todayCleanings}
          </div>
        : ''}
        {tomorrowCleanings.length > 0 ? 
          <div>
            <div className="PageDesc">JUTRZEJSZE SPRZĄTANIA</div>
            {this.state.fetching ? <div className="LoadingWrapper"><ReactLoading type="spin" color="#f2f2f2" /></div> : tomorrowCleanings}
          </div>
        : ''}
        {todayCleanings.length === 0 && tomorrowCleanings.length === 0 ?
          <div style={{textAlign: 'center'}}>
            Nie posiadasz żadnych sprzątań
          </div>
        : ''}
        <div className={`FamiliarWithCleanings ${this.state.confirmedCleanings ? 'Acquainted' : ''}`}>
          <button onClick={() => this.handleCleaningsConfirm()} type="button" disabled={this.state.confirmedCleanings ? true : false}>
            Zapoznałam/-em się z dzisiejszymi sprzątaniami
          </button>
        </div>
      </div>
    )
  }
}

export default CleaningsList