import React from 'react'

class CleaningDetails extends React.Component {
  render() {
    const date = new Date(this.props.details.moved_date ?? this.props.details.date).toLocaleDateString('pl-PL')
    const encodedAddress = encodeURI(this.props.details.job_address ?? this.props.details.cleaning_address)
    const loggedEmployee = JSON.parse(localStorage.getItem('user'))
    const otherEmployees = this.props.details.employees.map((emp, i) => {
      if (loggedEmployee.id !== emp.id) {
        return (
          <div key={i} className="Detail OtherEmployee">
            <div className="DetailName">
              {emp.name}
              {emp.employee_dataset.phone ?
                <a href={`tel:${emp.employee_dataset.phone}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone-call"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                  <span>{emp.employee_dataset.phone}</span>
                </a> : ''
              }
            </div>
            <div className="DetailValue">{emp.pivot.time} godz.</div>
          </div>
        )
      } else {
        return ''
      }
    })

    return (
      <div className="CleaningDetails">
        <div className="Detail">
          <div className="DetailName">Klient</div>
          <div className="DetailValue">{this.props.details.job.owner.name}</div>
        </div>
        <div className={otherEmployees.length > 1 ? 'Employees-Info' : ''}>
          <div className="Detail">
            <div className="DetailName">Ilość godz.</div>
            <div className="DetailValue">{this.props.details.employees.map(emp => {
              return loggedEmployee.id === emp.id ? emp.pivot.time : ''
            })} godz.</div>
          </div>
          {otherEmployees.length > 1 ?
            <div className="Detail OtherEmployee Header">
              <div className="DetailName">Inni pracownicy</div>
            </div> : ''
          }
          {otherEmployees}
        </div>
        <div className="Detail">
          <div className="DetailName">Data</div>
          <div className="DetailValue">{date}</div>
        </div>
        {this.props.details.cleaning_notes ?
          <div className="Detail Detail--Block">
            <div className="DetailName">Dodatkowe informacje do sprzątania</div>
            <div className="DetailValue">{this.props.details.cleaning_notes}</div>
          </div> : ''}
        {this.props.details.cleaning_products_place ?
          (<div className="Detail">
            <div className="DetailName">Produkty czyszczące</div>
            <div className="DetailValue">{this.props.details.cleaning_products_place}</div>
          </div>) : ''}
        {this.props.details.key_place ?
          <div className="Detail">
            <div className="DetailName">Miejsce klucza</div>
            <div className="DetailValue">{this.props.details.key_place}</div>
          </div> : ''}
        {this.props.details.access_code ?
          <div className="Detail">
            <div className="DetailName">Kod dostępu</div>
            <div className="DetailValue">{this.props.details.access_code}</div>
          </div> : ''}
        {this.props.details.nearest_metro_station ?
          <div className="Detail">
            <div className="DetailName">Najbliższa stacja metra</div>
            <div className="DetailValue">{this.props.details.nearest_metro_station}</div>
          </div> : ''}
        <div className="Detail Detail--Block">
          <div className="DetailName">Notatki pracy</div>
          <div className="DetailValue">{this.props.details.notes ?? '-'}</div>
        </div>

        <iframe width="100%" title={`Map for cleaning ${this.props.details.job.owner.name}`} height="300" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={`https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${encodedAddress}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}></iframe>
      </div>
    )
  }
}

export default CleaningDetails