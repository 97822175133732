import React from 'react'
import Notifications from './Notifications'
import Calendar from './Calendar'
import { Switch, Route } from 'react-router-dom'

class Content extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/powiadomienia">
          <Notifications />
        </Route>
        <Route path="/">
          <Calendar />
        </Route>
      </Switch>
    )
  }
}

export default Content